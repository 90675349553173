.course-details {
  display: flex;
  flex-direction: column;
  padding-bottom: 5vh;
  height: auto;
  min-height: 100vh;
  background-color: #f8f9fa;
  direction: rtl;

  .big-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 5vh;
    padding-left: 5vh;
    gap: 10%;
  }

  .start-div {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    height: fit-content;
  }

  .content {
    width: 100%;
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  h1 {
    font-size: 2em;
    color: #333;
  }

  p {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
    margin-top: 10px;
  }

  .course-learn {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .course-learn h2 {
    margin-bottom: 10px;
    color: #333;
  }

  .course-learn ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  .course-learn li {
    margin-bottom: 8px;
    color: #555;
    font-size: 16px;
  }

  .instructor-info {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      width: 75px;
      height: 75px;
    }
  }

  .instructor-image {
    width: 150px;
    /* Fixed width, adjust based on your design needs */
    height: 150px;
    /* Fixed height to maintain aspect ratio */
    border-radius: 50%;
    /* Circular image */
    margin-bottom: 10px;
    object-fit: cover;
    /* Ensures the image covers the area without being stretched */
  }

  h2 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 5px;
    /* Additional spacing from the image to the name */
  }

  button {
    padding: 10px 20px;
    background-color: #004080;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5em;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 100%;

    &:hover {
      background-color: #0056b3;
    }
  }

  .copy-rights {
    text-align: center;
    margin-top: 40px;
    font-size: 0.8em;
    color: #666;
  }

  .course-content {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
  }

  .course-content li {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #e9ecef;
  }

  .course-content li.finished {
    background-color: #d1e7dd;
  }

  .course-content li.start-from {
    background-color: #cfe2f3;
    cursor: pointer;
  }

  .course-content li.start-from:hover {
    background-color: #bfd2e6;
  }

  .course-content li.locked {
    cursor: not-allowed;
  }

}

@media only screen and (max-width: 768px) {
  .course-details {
    .big-row {
      flex-direction: column;
      padding-right: 2vh;
      padding-left: 2vh;
    }

    .content {
      order: 2;
    }

    .start-div {
      order: 1;
    }
  }
}