.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: black;

    .menu {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .title {
        font-family: 'Poppins';
        font-size: 1.5em;
        color: white;
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        .separator {
            width: 1px;
            height: 30px;
            background-color: #fff;
        }

        .languages {
            direction: ltr;

            .btn-secondary {
                padding: 0px;
                background-color: transparent;

                &:hover {
                    background-color: transparent !important;
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-item {
                padding: .25rem 1rem;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
            }

            img {
                width: 18px;
                height: 12px;
                border-radius: 2.5px;
            }
        }
    }

}