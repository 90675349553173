.registration-flow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .heading {
    padding: 30px;
  }

  .user-info {
    margin: 30px 0px;
    display: flex;
    gap: 30px;
    align-items: center;

    img {
      border-radius: 10px;
      height: 100px;
    }

    .user {
      text-align: center;
      font-family: 'Poppins';
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: medium;

      .name {
        font-weight: 600;
      }

      .passport {
        font-weight: 400;
        font-style: italic;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      margin: 0px;
    }

    .certificate {
      padding: 15px 20px;
      background: white;
      margin: 30px;
      border-radius: 10px;
      cursor: pointer;
      font-size: larger;
      font-weight: 900;
      align-items: center;
      gap: 10px;
    }
  }
}

.main-title {
  font-size: 24px;
  color: #121212;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
}

.sec-title {
  font-size: 14px;
  color: #616161;
  text-align: center;
  margin-bottom: 10px;
}

.col {
  text-align: center;
}

.card-header {
  border-right: 2px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}

.card-body {
  border-right: 2px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}