.login {
    .login-flow {
        padding: 30px 0;
        background-color: #fff;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #cacaca;

        .heading {
            margin: 0 auto;
            max-width: 90%;
            font-size: 0;

            .main-title {
                margin-bottom: 5px;
                font-size: 20px;
            }

            .sec-title {
                font-size: 11px;
            }
        }
    }

    .content {
        background-color: #eee;
        overflow: hidden;
        padding-top: 40px;
        padding-bottom: 40px;
        min-height: calc(100vh - 147px);

        .inner-content {
            width: 500px;
            max-width: calc(100% - 30px);
            margin: 0 auto !important;

            .main-title {
                &.form-title {
                    font-size: 20px;
                }
            }

            .sec-title {
                font-size: 12px;
            }

            form {
                display: block;
                margin: 0 auto;

                .more-inputs {
                    padding-bottom: 20px;

                    button {
                        margin-top: 5px;
                        max-width: none;
                        display: flex;
                        justify-content: center;
                    }
                }

                .input-holder {
                    display: block;
                    position: relative;
                    margin-bottom: 30px;
                    z-index: 1;
                    background-color: #fff;

                    .input-comment {
                        left: 0;
                        position: absolute;
                        top: -25px;
                        color: #666;
                        font-size: 11px;

                        &.forgot {
                            color: #2697d1 !important;
                            cursor: pointer;
                        }
                    }
                }

                .need-an-account {
                    color: #484848;
                    font-size: 13px;
                    margin: 30px 0;
                }

                .need-an-account>span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 20px;
                }

                .need-an-account>a {
                    font-size: 16px;
                    display: inline-block;
                    vertical-align: middle;
                    color: #219f45;
                }

                .login-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                }


            }

        }

        .buttons {
            margin-top: 60px;
            text-align: center;

            >.button {
                display: inline-block;
                margin: 0 10px 20px;

                &.button-green {
                    background-color: #219f45;
                }

                &.button-grey {
                    background-color: #7f7f7f;
                }
            }
        }
    }
}