.thread-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
  min-height: 100vh;
  background-color: #f8f9fa;
}


/* Add post **********************************/

.add-post {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%;
}

.icon-add {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.thread-add-button {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  width: 20%;

  font-size: 22px;
  line-height: 28px;
}

.thread-add-button:hover {
  background-color: #0056b3;
}


.add-bar {
  flex-grow: 1;
}

.add-bar input {
  height: 60px;
}

/* ************************************************************************************************/


.thread-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 60%;
}

.thread-post {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.post-user {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

}

.post-user img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0px 20px;
}

.post-content {
  font-size: 22px;
  margin-bottom: 10px;
}

.post-actions {
  display: flex;
  align-items: center;
  gap: 40px;
}

.comments-section {
  margin-top: 10px;
}

.comment {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 5px;
}

.add-comment {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.comment-bar {
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .add-post {
    width: 90%;
  }

  .thread-posts {
    width: 90%;
  }

  .thread-add-button {
    width: initial;
  }

}