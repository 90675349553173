.registration {

    .registration-flow {
        padding: 30px 0;
        background-color: #fff;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #cacaca;

        .heading {
            margin: 0 auto;
            max-width: 90%;
            font-size: 0;

            .main-title {
                margin-bottom: 5px;
                font-size: 20px;
            }

            .sec-title {
                font-size: 11px;
            }
        }

        .registration-step {
            position: absolute;
            width: 100%;
            bottom: 0;
            transform: translateY(50%);
            text-align: center;
            overflow: hidden;

            >li {
                display: inline-block;
                background-color: #fff;
                margin: 0 5%;
                width: 40px;
                text-align: center;
                height: 40px;
                line-height: 38px;
                border: 2px solid #bebebe;
                border-radius: 50%;
                color: #cacaca;
                position: relative;

                &.active {
                    color: #219f45;
                    border-color: #219f45;

                    &:after {
                        left: 100%;
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: #219f45;
                        height: 2px;
                        width: 5000px;
                        z-index: -1;
                    }
                }

                &.done {
                    background-color: #219f45;
                    border-color: #219f45;
                    color: white;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        line-height: inherit;
                        border-radius: 50%;
                        background-color: #219f45;
                        color: #fff;
                    }
                }
            }
        }
    }

    .content {
        background-color: #eee;
        overflow: hidden;
        padding-top: 40px;
        padding-bottom: 40px;
        min-height: calc(100vh - 147px);

        .inner-content {
            width: 500px;
            max-width: calc(100% - 30px);
            margin: 0 auto !important;

            .main-title {
                &.form-title {
                    font-size: 20px;
                }
            }

            .sec-title {
                font-size: 12px;
            }

            form {
                display: block;
                margin: 0 auto;

                .more-inputs {
                    padding-bottom: 20px;

                    button {
                        margin-top: 5px;
                        max-width: none;
                        display: flex;
                        justify-content: center;
                    }
                }

                .verify-success {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 50px 0px;
                }


            }

            .upload-images {
                margin: 50px 0px;
                display: flex;
                flex-direction: column;
                gap: 50px;

                .img-title {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    p {
                        margin-bottom: 0px;
                    }

                    .title {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        p {
                            margin-bottom: 0px;
                        }

                        span {
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }

                .tips-title {
                    /* margin-top: 40px; */
                    font-size: 16px;

                    .tips {
                        display: flex;
                        gap: 30px;
                        align-items: center;
                        margin-top: 20px;

                        .each-tip {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-size: 12px;
                            font-style: italic;

                            img {
                                width: 70px;
                                height: 49.5px;
                            }


                        }
                    }
                }
            }

        }

        .buttons {
            margin-top: 60px;
            text-align: center;

            >.button {
                display: inline-block;
                margin: 0 10px 20px;

                &.button-green {
                    background-color: #219f45;
                }

                &.button-grey {
                    background-color: #7f7f7f;
                }
            }
        }
    }


}