.course {
  min-height: 100vh;

  .course-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;

    .course-separator {
      display: none;
      width: 1px;
      background-color: rgb(209 207 207);
    }
  }

  .video-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large;
    background-color: #eeeeee;
  }

  .video-title {
    margin: 30px 0px;
    padding: 30px 20px;
    color: #121212;
  }

  .video-player {
    width: 100%;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
  }

  .menu-container {
    right: 0;
    position: fixed;
    width: 80%;
    z-index: 100;
    background-color: white;
    padding: 30px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
    overflow: scroll;

    &.laptop {
      display: none;
    }
  }

  /* .completed {
    text-decoration: line-through;
  } */

  .quiz-container {
    margin: 100px 0px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quiz-container p {
    margin-bottom: 10px;
  }

  .quiz-container button {
    width: 100%;
    height: 28px;
    border: 1px solid !important;
    border-radius: 10px;
    padding: 20px 20px;
    /* Remove one of these lines */
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Light background color */
    color: #000;
    /* Dark text color */
  }

  .quiz-container button.correct-answer {
    background-color: green;
  }

  .quiz-container button.wrong-answer {
    background-color: red;
  }

  a {
    text-decoration: none;
  }

  .buttons {
    display: flex;
    gap: 20px;
    margin-top: 50px;

    button {
      min-width: max-content;
      padding: 0px 50px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .course {
    .menu-container {
      &.mobile {
        display: none;
      }

      &.laptop {
        display: block !important;
        position: initial;
        width: 25%;
        overflow: hidden;
      }
    }

    .video-container {
      width: 75%;
    }

    .course-container {
      .course-separator {
        display: initial;
      }
    }

  }

}

@media only screen and (max-width: 768px) {
  .course {
    .buttons {
      position: fixed;
      bottom: 0;
      padding: 10px;
      background: white;
      width: 100%;

      button {
        width: 100%;
        padding: 0px 20px;
        margin: 0px;
        max-width: initial;
      }
    }
  }

}