.home {
    background-image: url(../../assets/img/image.jpg);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 480px;
    background-color: #000;
    position: relative;
    flex-direction: column;
    font-size: 2vh;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vh;
    overflow: hidden;
    transition: margin-right .4s .4s ease-in-out, margin-left .4s .4s ease-in-out, background-position .7s ease-in-out;
    align-items: center;

    .home-titles {
        text-align: center;
        color: #fff;
        transition: transform .7s ease-in-out, opacity .7s ease-in-out;
        padding: 0 10px;
        max-height: 300px;

        .first {
            font-size: 210%;
            margin-bottom: 12px;
            font-weight: 700;
        }

        .second {
            font-size: 105%;
            font-weight: 500;
        }
    }

    .login-register {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .7s ease-in-out, opacity .7s ease-in-out;

        .login {
            height: auto;
            line-height: normal;
            color: #fff !important;
        }

        .register {
            height: 33px;
            line-height: 32px;
            padding: 0 10px;
        }

        >span {
            font-size: 13px;
            display: block;
            margin: 0 20px;
            color: #fff;
        }

    }

    .copy-rights {
        font-size: 75%;
        color: #ccc;
        text-align: center;
        direction: initial;
        position: relative;
        z-index: 77777;
    }
}