@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Cairo', 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  text-align: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  text-align: center;
  width: 100%;
  max-width: 130px;
  margin: 0 auto;
  height: 45px;
  line-height: 45px;
  color: #fff !important;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all .1s linear;

  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 5px;
  font-family: 'Cairo';
  font-weight: 700;
  justify-content: center;

  &.button-transparent {
    color: #7f7f7f !important;
    background-color: transparent;
    padding: 0;
  }

  &.width-auto {
    width: auto;
    max-width: none;
  }

  &.button-green {
    background-color: #219f45;
  }

  >span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  &:disabled {
    opacity: .7;
    background-color: #afafaf !important;
    color: #fff !important;
    cursor: default;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

  }

  &.button-blue {
    background-color: #2697d1;
  }

}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-title {
  color: #121212;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.sec-title {
  font-size: 14px;
  color: #616161;
  text-align: center;
  margin-bottom: 10px;
}

.main-label {
  display: block;
  color: #616161;
  font-size: 15px;
  margin: 10px 0px;
  position: relative;
  font-weight: 700;
}

.options-label {
  color: #616161;
  font-size: 14px;
  margin: 0px 5px;
  position: relative;
}

.main-input {
  height: 45px;
  border: none;
  box-shadow: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 16px;
}

.main-input::placeholder {
  color: #999;
  font-style: italic;
  font-size: 12px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-secondary {
  width: 100%;
  background-color: white;
  color: black;
  height: 45px;
  border: none;
}

.btn-secondary:active {
  background-color: white !important;
  color: black !important;
  border: none !important;
}

.btn-secondary:hover {
  background-color: white !important;
  color: black !important;
  border: none !important;
}

.btn-secondary:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  right: 0px;
}

.dropdown-item {
  text-align: start;
}

input {
  appearance: auto;
}

.text-left {
  text-align: start !important;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    height: 45px;
    border: none;
    box-shadow: none;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 8px 16px;
  }
}

.react-tel-input {
  direction: ltr;
  font-family: 'Poppins' !important;

  .form-control {
    width: 100% !important;
    border: 0px !important;
    height: 45px;
  }

  .country-list .country {
    text-align: start;
  }
}

button:focus {
  outline: 0px;
}

#g_a11y_announcement {
  height: 0px !important;
  left: 0px !important;
}