.course-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: fit-content;

    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        font-size: 18px;
        width: 100%;
        color: white;
        padding: 25px;
        border-radius: 10px;
        border: unset;
        cursor: pointer;
    }

    .certificate {
        padding: 15px 20px;
        background: white;
        margin: 30px;
        border-radius: 10px;
        cursor: pointer;
        font-size: larger;
        font-weight: 900;
        align-items: center;
        gap: 10px;
    }
}