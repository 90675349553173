.profile {

    .user-nav {
        background-color: #fff;
        margin-right: auto;
        margin-left: auto;
        padding: 15px;

        display: flex;
        align-items: center;
        gap: 20px;

        .image-holder {
            img {
                /* width: 60px; */
                height: 60px;
                border: 2px solid #bebebe;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .info {
            .welcome {
                color: #121212;
                font-size: 11px;
                font-weight: 700;
                margin: 0;
            }

            .name {
                font-size: 13px;
                font-weight: 700;
                margin: 0;
            }

            .id {
                font-size: 11px;
                color: #bebebe;

                .id-num {
                    letter-spacing: 1px;
                    color: #212529;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                }
            }
        }
    }

    .profile-info {
        background-color: #f8f9fa;
        padding: 5vh;

        .page-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 1.5;
            width: 100%;
            text-align: center;
        }
    }

    .white-container {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .dropdown {
        border: 1px solid;
        border-radius: 5px;
    }

    .main-input {
        border: 1px solid;
    }

    .socail-media-link {
        direction: ltr;
        display: flex;
        gap: 5px;
    }

}

@media only screen and (max-width: 768px) {
    .profile {
        .profile-info {
            padding: 2vh;
        }
    }

}